import {createSelector} from '@ngrx/store';
import {selectDevKoppelpartij} from '../app.state';
import {Endpoint} from './dev-koppelpartij.state';
import {EndpointNaamEnum, mapEndpointUitzonderingen, PermissionNaamEnum} from "../../shared/PermissiesUtil";

export const selectKoppelpartij = createSelector(
  selectDevKoppelpartij,
  state => state.koppelpartij
);

export const selectOAuthClient = createSelector(
  selectDevKoppelpartij,
  state => state.oauthclient
);

export const selectAllEndpoints = createSelector(
  selectDevKoppelpartij,
  state => state.endpoints
);

export const selectAllLeesPermissies = createSelector(
  selectDevKoppelpartij,
  state => state.leespermissies
);

export const selectAllSchrijfPermissies = createSelector(
  selectDevKoppelpartij,
  state => state.schrijfpermissies
);

export const selectAllOrganisaties = createSelector(
  selectDevKoppelpartij,
  state => state.organisaties
);

export const selectSuccesfullySent = createSelector(
  selectDevKoppelpartij,
  state => state.succesfullySent
);

export const selectBenodigdeVeldpermissies = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.benodigdeVeldpermissies
);

export const selectKoppelpartijAfbeelding = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.koppelpartijAfbeeldingen
);

export const selectWritePermissies = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.writePermissies
);

export const selectGecategoriseerdeEndpoints = createSelector(
  selectAllEndpoints,
  selectAllLeesPermissies,
  selectAllSchrijfPermissies,
  selectBenodigdeVeldpermissies,
  selectWritePermissies,
  (allEndpoints, allLeespermissies, allSchrijfpermissies, benodigdeVeldpermissies, benodigdeWritepermissies) => {

    if (allSchrijfpermissies && allLeespermissies && allEndpoints) {
      let leespermissies = allLeespermissies;
      if (benodigdeVeldpermissies) {
        leespermissies = allLeespermissies.map(p => {
          const benodigdeVeldpermissieFound = benodigdeVeldpermissies.find(bp => p.fieldIndex === bp.fieldIndex && p.entityIndex === bp.entityIndex);
          if (benodigdeVeldpermissieFound) {
            return {...p, selected: benodigdeVeldpermissieFound.selected, verplicht: benodigdeVeldpermissieFound.verplicht, additionalProperties: benodigdeVeldpermissieFound.additionalProperties};
          }
          return p;
        });
      }

      const endpoints: Endpoint[] = [
        {naam: EndpointNaamEnum.LEERLING, categorieen: [
            {naam: 'Personalia', velden: []},
            {naam: 'Plaatsing', velden: []},
            {naam: 'Onderwijsinhoudelijk', velden: []},
            {naam: 'Resultaten', velden: []},
            {naam: 'Toegangspassen', velden: []},
            {naam: 'Kluisafnames', velden: []},
            {naam: 'Rooster', velden: []}
          ]},
        {naam: EndpointNaamEnum.MEDEWERKER, categorieen: [
            {naam: 'Personalia', velden: []},
            {naam: 'Aanstelling', velden: []},
            {naam: 'Toegangspassen', velden: []},
            {naam: 'Agenda', velden: []}
          ]},
        {naam: EndpointNaamEnum.LESGROEP, categorieen: [
            {naam: 'Algemeen', velden: []},
          ]},
        {naam: EndpointNaamEnum.OUDER_VERZORGER, categorieen: [
            {naam: 'Personalia', velden: []},
            {naam: 'Leerlingen', velden: []}
          ]},
        {naam: EndpointNaamEnum.LEERLING_ACCOUNT, categorieen: [
            {naam: 'Algemeen', velden: []},
          ]},
        {naam: EndpointNaamEnum.MEDEWERKER_ACCOUNT, categorieen: [
            {naam: 'Algemeen', velden: []},
          ]},
        {naam: EndpointNaamEnum.OUDER_VERZORGER_ACCOUNT, categorieen: [
            {naam: 'Algemeen', velden: []},
          ]},
        {naam: EndpointNaamEnum.VESTIGING, categorieen: [
            {naam: 'Algemeen', velden: []},
            {naam: 'Aanmeldportaal', velden: []}
          ]},
          {naam: EndpointNaamEnum.OVERIG, categorieen: [
            {naam: 'Algemeen', velden: []},
          ]}
      ];

      const medewerkerEndpoint = endpoints.find( e => e.naam === EndpointNaamEnum.MEDEWERKER);
      const leerlingEndpoint = endpoints.find( e => e.naam === EndpointNaamEnum.LEERLING);
      const ouderEndpoint = endpoints.find( e => e.naam === EndpointNaamEnum.OUDER_VERZORGER);
      const overigEndpoint = endpoints.find( e => e.naam === EndpointNaamEnum.OVERIG);

      leespermissies = [...leespermissies].filter(a => !a.additionalProperties.find(p => p.type === 'NestedEntity'));

      for (const permission of leespermissies) {
        const permissionMapped = {
          naam: permission.name,
          entityIndex: permission.entityIndex,
          fieldIndex: permission.fieldIndex,
          selected: permission.selected,
          verplicht: permission.verplicht,
          properties: permission.additionalProperties,
          subFields: null,
        };
        const endpoint = endpoints.find(e => permission.entityName === e.naam);
        if (endpoint) {
          if (
            [EndpointNaamEnum.LEERLING.valueOf(), EndpointNaamEnum.MEDEWERKER.valueOf()].includes(endpoint.naam) &&
            [PermissionNaamEnum.VESTIGING, PermissionNaamEnum.HOOFDVESTIGING, PermissionNaamEnum.LESGROEPEN, PermissionNaamEnum.VAKKEUZES, PermissionNaamEnum.IS_DOCENT, PermissionNaamEnum.FUNCTIE]
              .map(value => value.valueOf()).includes(permission.name) && permission.name != 'Vrije velden'
          ) {
            endpoint.categorieen[1].velden.push(permissionMapped);
          } 
          else if(permission.name === 'Vrije velden'){
            let vrijveldcategorie = endpoint.categorieen.find(c => c.naam === permission.name);
            if(!vrijveldcategorie){
              endpoint.categorieen.push({naam: 'Vrije velden', velden: [
                {naam: 'Lezen', fieldIndex: permission.fieldIndex, verplicht:false, selected: permission.selected, entityIndex: permission.entityIndex, properties: permission.additionalProperties}
              ]})
            }
            else{
              vrijveldcategorie.velden.push( {naam: 'Lezen', fieldIndex: permission.fieldIndex, verplicht:false, selected: permission.selected, entityIndex: permission.entityIndex, properties:  permission.additionalProperties});
            }
          }
          else {
            endpoint.categorieen[0].velden.push(permissionMapped);
          }
        }
        if (permission.entityName === PermissionNaamEnum.LEERLING_REFERENTIE) {
          ouderEndpoint.categorieen[1].velden.push(permissionMapped);
        }
        mapEndpointUitzonderingen(permission, permissionMapped, leerlingEndpoint, medewerkerEndpoint, ouderEndpoint, overigEndpoint);
      }

      let schrijfpermissies = [];
      if (benodigdeWritepermissies) {
        schrijfpermissies = allSchrijfpermissies.filter(p => benodigdeWritepermissies.find(bp => p.index === bp.index));
      }

      allSchrijfpermissies.forEach(sp => {
        let addToEndpoint = endpoints.find(e => e.naam === sp.endpoint);
        if(addToEndpoint && sp.naam === sp.endpoint+' vrije velden'){
          let vrijveldcategorie = addToEndpoint.categorieen.find(c => c.naam === 'Vrije velden');
          if(!vrijveldcategorie){
            addToEndpoint.categorieen.push({naam: 'Vrije velden', velden: [
              {naam: 'Schrijven', fieldIndex: sp.index, verplicht:false, selected: schrijfpermissies.find(p => p.index === sp.index), entityIndex: null, properties: [{type: 'OnlyOptional', description:''}]}            
            ]})
          }
          else{
            vrijveldcategorie.velden.push({naam: 'Schrijven', fieldIndex: sp.index, verplicht:false, selected: schrijfpermissies.find(p => p.index === sp.index), entityIndex: null, properties: [{type: 'OnlyOptional', description:''}]});
          }
        }
        else{
          if (!addToEndpoint) {
            addToEndpoint = endpoints.find(e => e.categorieen.find(c => c.naam === sp.endpoint));
          }
          let schrijfpermissie = addToEndpoint.categorieen.find(c => c.naam ==='Schrijfpermissies');
          if(!schrijfpermissie){
            addToEndpoint.categorieen.push({
              naam: 'Schrijfpermissies', velden: []
            });
            schrijfpermissie = addToEndpoint.categorieen.find(c => c.naam ==='Schrijfpermissies');
          }
          schrijfpermissie.velden.push({
            entityIndex: null,
            fieldIndex: sp.index,
            naam: sp.naam,
            selected: false,
            verplicht: schrijfpermissies.find(p => p.index === sp.index),
            properties: [],
          });
        }
      }
    );

      return endpoints.map(e => {
        e.categorieen = e.categorieen.filter(c => c.velden.length > 0);
        return e;
      }).filter(e => e.categorieen.length > 0);

    } else return [];
  }
);

export const selectUUID = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.uuid
);

export const selectKoppelpartijNaam = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.koppelpartijNaam
);

export const selectKoppelpartijOmschrijving = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.koppelpartijOmschrijving
);

export const selectKoppelpartijKorteOmschrijving = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.koppelpartijKorteOmschrijving
);

export const selectDocentLink = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.docentLink
);

export const selectLeerlingLink = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.leerlingLink
);

export const selectKoppelpartijAfbeeldingen = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.koppelpartijAfbeeldingen
);

export const selectOAuthClientId = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.oAuthClientId
);

export const selectKoppelpartijBedrijfsnaam = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.koppelpartijBedrijfsnaam
);

export const selectKoppelpartijLogoUrl = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.koppelpartijLogoUrl
);

export const selectEndpoints = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.endpoints
);

export const selectOrganisaties = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.zichtbaarVoorOrganisaties
);
