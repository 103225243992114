import {Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {combineLatest, Observable, Subject, Subscription} from 'rxjs';
import {filter, map, take, withLatestFrom} from 'rxjs/operators';
import { InputTextViewModel } from '../layout/input-text/input-text.component';
import {Store} from '@ngrx/store';
import {AppState} from '../state/app.state';
import {ModalwindowService} from '../services/modalwindow.service';
import {
  selectAllLeesPermissies,
  selectAllSchrijfPermissies,
  selectBenodigdeVeldpermissies,
  selectGecategoriseerdeEndpoints,
  selectKoppelpartij, selectKoppelpartijAfbeeldingen,
  selectKoppelpartijNaam, selectKoppelpartijOmschrijving,
  selectOAuthClient,
  selectSuccesfullySent,
  selectAllOrganisaties,
  selectOrganisaties
} from '../state/dev-koppelpartij/dev-koppelpartij.selectors';
import {
  addSchrijfpermissie,
  deleteSchrijfpermissie,
  saveKoppelpartij,
  updateBenodigdeVeldpermissie,
  updateDocentLink,
  updateKoppelpartijBedrijfsnaam,
  updateKoppelpartijLogoUrl,
  updateKoppelpartijNaam, updateKoppelpartijOmschrijving,
  updateKoppelpartijAfbeeldingen,
  updateKorteOmschrijving,
  updateLeerlingLink,
  updateOpLeerlingNiveauGeautoriseerd,
  updateKoppelpartijOrganisaties, setEndpointsSelected,
} from '../state/dev-koppelpartij/dev-koppelpartij.actions';
import {Endpoint, Veld, Koppelpartij} from '../state/dev-koppelpartij/dev-koppelpartij.state';
import {AVGExtendedVeldpermissie, AVGOAuthClient, AVGOrganisatie, AVGWritePermissie} from '../dto/avg-dashboard-model-classes';
import {InputPermissionEndpointViewModel} from '../layout/input-permission-endpoint/input-permission-endpoint.component';
import {intrinsicDistinct} from '../stateless/privacydashboard.functions';
import {InputTextareaViewModel} from '../layout/input-textarea/input-textarea.component';
import {InputCheckboxViewModel} from '../layout/input-checkbox/input-checkbox.component';
import {ToastrService} from 'ngx-toastr';
import { OrganisatieSelectorViewModel, SelectableOrganization } from '../old-dashboarding/avg-organisatie-selector/avg-organisatie-selector.component';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-dev-koppelpartij-bewerken-page',
    templateUrl: './dev-koppelpartij-bewerken-page.component.html',
    styleUrls: ['./dev-koppelpartij-bewerken-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevKoppelpartijBewerkenPageComponent implements OnInit, OnDestroy {
  public koppelpartij$: Observable<Koppelpartij>;
  public oauthclient$: Observable<AVGOAuthClient>;
  public endpoints$: Observable<Endpoint[]>;
  public sent$: Observable<boolean>;
  public koppelpartijOmschrijving$: Observable<string> = this.store.select(selectKoppelpartijOmschrijving);
  public koppelpartijAfbeeldingen$: Observable<{image: string, thumbImage: string}[]>;

  public koppelpartijNaamViewModel: InputTextViewModel;
  public koppelpartijBedrijfsnaamViewModel: InputTextViewModel;
  public koppelpartijLogoUrlViewModel: InputTextViewModel;
  public koppelpartijDocentLinkViewModel: InputTextViewModel;
  public koppelpartijLeerlingLinkViewModel: InputTextViewModel;

  public koppelpartijKorteOmschrijvingViewModel: InputTextareaViewModel;

  public koppelpartijOpLeerlingNiveauGeautoriseerdViewModel: InputCheckboxViewModel;

  private leesPermissiesToBeRequiredWithWritePermission = ['Gebruikersnaam', 'Emailadres', 'UUID', 'Usertype', 'Remote identifiers'];

  private subscriptions: Subscription[] = [];

  private readyToSend;

  public organizationsViewModel: OrganisatieSelectorViewModel;

  private onOrganizationSelectionUpdated: Subject<SelectableOrganization[]> = new Subject();

  private LEERLING_ACCOUNT = 15;
  private MEDEWERKER_ACCOUNT = 16;
  private OUDER_VERZORGER_ACCOUNT = 17;
  private LEERLING_REFERENTIE = 20;
  private ROLLEN = 7;
  private UUID = 0;

  constructor(private store: Store<AppState>, private modalWindowService: ModalwindowService,
              private toastr: ToastrService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.oauthclient$ = this.store.select(selectOAuthClient);
    this.koppelpartij$ = this.store.select(selectKoppelpartij);
    this.endpoints$ = this.store.select(selectGecategoriseerdeEndpoints).pipe(
      filter(e => e?.length > 0),
      take(1)
    );
    this.sent$ = this.store.select(selectSuccesfullySent);
    this.koppelpartijAfbeeldingen$ = this.store.select(selectKoppelpartijAfbeeldingen).pipe(map((afbeeldingen: string[]) => {
        return (afbeeldingen && afbeeldingen.length) ? afbeeldingen.map(afbeelding => Object.assign({image: afbeelding, thumbImage: afbeelding})) : [];
    }));

    this.koppelpartijNaamViewModel = {
      onGetInput: this.koppelpartij$.pipe(map(k => k?.koppelpartijNaam)),
      doOnBlur: new Subject<string>(),
    };
    this.subscriptions.push(this.koppelpartijNaamViewModel.doOnBlur.subscribe(naam => this.store.dispatch(updateKoppelpartijNaam({koppelpartijNaam: naam}))));

    this.koppelpartijBedrijfsnaamViewModel = {
      onGetInput: this.koppelpartij$.pipe(map(k => k?.koppelpartijBedrijfsnaam)),
      doOnBlur: new Subject<string>(),
    };
    this.subscriptions.push(this.koppelpartijBedrijfsnaamViewModel.doOnBlur.subscribe(naam => this.store.dispatch(updateKoppelpartijBedrijfsnaam({koppelpartijBedrijfsnaam: naam}))));

    this.koppelpartijLogoUrlViewModel = {
      onGetInput: this.koppelpartij$.pipe(map(k => k?.koppelpartijLogoUrl)),
      doOnBlur: new Subject<string>(),
    };
    this.subscriptions.push(this.koppelpartijLogoUrlViewModel.doOnBlur.subscribe(url => this.store.dispatch(updateKoppelpartijLogoUrl({koppelpartijLogoUrl: url}))));

    this.koppelpartijDocentLinkViewModel = {
      onGetInput: this.koppelpartij$.pipe(map(k => k?.docentLink)),
      doOnBlur: new Subject<string>(),
    };
    this.subscriptions.push(this.koppelpartijDocentLinkViewModel.doOnBlur.subscribe(link => this.store.dispatch(updateDocentLink({docentLink: link}))));

    this.koppelpartijLeerlingLinkViewModel = {
      onGetInput: this.koppelpartij$.pipe(map(k => k?.leerlingLink)),
      doOnBlur: new Subject<string>(),
    };
    this.subscriptions.push(this.koppelpartijLeerlingLinkViewModel.doOnBlur.subscribe(link => this.store.dispatch(updateLeerlingLink({leerlingLink: link}))));
    this.koppelpartijKorteOmschrijvingViewModel = {
      onGetInput: this.koppelpartij$.pipe(map(k => k?.koppelpartijKorteOmschrijving)),
      doOnBlur: new Subject<string>(),
    };
    this.subscriptions.push(this.koppelpartijKorteOmschrijvingViewModel.doOnBlur.subscribe(omschrijving => this.store.dispatch(updateKorteOmschrijving({koppelpartijKorteOmschrijving: omschrijving}))));

    this.koppelpartijOpLeerlingNiveauGeautoriseerdViewModel = {
      onGetValue: this.koppelpartij$.pipe(map(k => k?.opLeerlingNiveauGeautoriseerd)),
      doOnClick: new Subject<boolean>(),
    };
    this.subscriptions.push(this.koppelpartijOpLeerlingNiveauGeautoriseerdViewModel.doOnClick.subscribe(_ => this.store.dispatch(updateOpLeerlingNiveauGeautoriseerd())));

    this.store.select(selectKoppelpartijNaam).subscribe(n => { this.readyToSend = n !== null; });

    this.organizationsViewModel = {
      onGetAllOrganizations: combineLatest([this.store.select(selectOrganisaties), this.store.select(selectAllOrganisaties)]).pipe(
          map(([selected, all]) =>
          {
              if (all === null)
              {
                  return [];
              }
              return all.map(org => ({...org, selected: selected.find(o => o.uuid === org.uuid) !== undefined}));
          })
      ),
      onOrganizationSelectedChanged: this.onOrganizationSelectionUpdated
    };

    this.onOrganizationSelectionUpdated.subscribe(orgs => {
      this.store.dispatch(updateKoppelpartijOrganisaties({
        zichtbaarVoorOrganisaties: orgs.filter(org => org.selected).map(org => ({
            naam: org.naam,
            uuid: org.uuid
          }))
        }
      ));
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public viewModelForEndpoint(endpoint: Endpoint): InputPermissionEndpointViewModel {
        const vm: InputPermissionEndpointViewModel = {
            onGetEndpoint: this.endpoints$.pipe(
              map( endpoints => endpoints.find(e => (e.naam === endpoint.naam))),
              intrinsicDistinct()
            ),
            onAdditionalPropertyClicked: new Subject(),
            onVeldOptioneelClicked: new Subject(),
            onVeldVerplichtClicked: new Subject()
        };

        this.subscriptions.push(vm.onAdditionalPropertyClicked.pipe(
          withLatestFrom(
            this.store.select(selectBenodigdeVeldpermissies),
            this.store.select(selectAllLeesPermissies),
        )).subscribe(([[[v, prop], _], benodigdeVeldPermissies, allLeesPermissies]) => {
            let relatedPermissie = benodigdeVeldPermissies?.find(p => this.fieldsAreEqual(p, v));
            if (!relatedPermissie) {
              relatedPermissie = allLeesPermissies?.find(p => this.fieldsAreEqual(p, v));
            }
            if (relatedPermissie) {
              v.properties = [{type: 'warning', description: prop}];
              const toAdd = [{...relatedPermissie, additionalProperties: (prop ? [{type: 'warning', description: prop}] : [])}];
              this.store.dispatch(updateBenodigdeVeldpermissie({benodigdeVeldpermissies: toAdd}));
            }
          }
        ));

        this.subscriptions.push(vm.onVeldOptioneelClicked.pipe(
          withLatestFrom(
            this.store.select(selectAllLeesPermissies),
            this.store.select(selectAllSchrijfPermissies),
            this.store.select(selectBenodigdeVeldpermissies),
            vm.onGetEndpoint,
        )).subscribe(([[v, _], allLeesPermissies, allSchrijfPermissies, benodigdeVeldpermissies, foundEndpoint]) => {
            v.verplicht = false;
            v.selected = !v.selected;
            this.updateBenodigdeVeldPermissies(allLeesPermissies, allSchrijfPermissies, benodigdeVeldpermissies, v, foundEndpoint);
          }));

        this.subscriptions.push(vm.onVeldVerplichtClicked.pipe(
          withLatestFrom(
            this.store.select(selectAllLeesPermissies),
            this.store.select(selectAllSchrijfPermissies),
            this.store.select(selectBenodigdeVeldpermissies),
            vm.onGetEndpoint
          )).subscribe(([[v, _], allLeesPermissies, allSchrijfPermissies, benodigdeVeldpermissies, foundEndpoint]) => {
            v.selected = false;
            v.verplicht = !v.verplicht;
            if (v.entityIndex) this.updateBenodigdeVeldPermissies(allLeesPermissies, allSchrijfPermissies, benodigdeVeldpermissies, v, foundEndpoint);
            else this.updateSchrijfpermissie(allLeesPermissies, allSchrijfPermissies, v, foundEndpoint);
        }));

        return vm;
  }

  private updateBenodigdeVeldPermissies(
    allLeesPermissies: AVGExtendedVeldpermissie[],
    allSchrijfPermissies: AVGWritePermissie[],
    benodigdeVeldpermissies: AVGExtendedVeldpermissie[],
    v: Veld,
    e: Endpoint
  ): void {
    if(v.naam === 'Lezen' || v.naam === 'Schrijven'){ 
      if(v.naam === 'Lezen'){
        const relatedLeesPermissie = allLeesPermissies.find(p => p.fieldIndex === v.fieldIndex && p.entityIndex === v.entityIndex);
        if (relatedLeesPermissie) {
          let toAdd = [{...relatedLeesPermissie, additionalProperties: v.properties}];
          toAdd = toAdd.map(veld => ({...veld, selected: v.selected}));
          this.store.dispatch(updateBenodigdeVeldpermissie({benodigdeVeldpermissies: toAdd})); 
          if(!v.selected){
            const relatedSchrijfPermissie = allSchrijfPermissies.find(p => p.endpoint === e.naam && p.naam.endsWith('vrije velden'))
            this.store.dispatch(deleteSchrijfpermissie({schrijfpermissie: relatedSchrijfPermissie}));
          }
        }
      }
      else{
        const relatedSchrijfPermissie = allSchrijfPermissies.find(p => p.index === v.fieldIndex);
        if (relatedSchrijfPermissie) {
          if(v.selected){
            const relatedLeesPermissie = allLeesPermissies.find(p => p.name === 'Vrije velden' && p.entityName === e.naam);
            if(relatedLeesPermissie){
              let toAdd = [{...relatedLeesPermissie}];
              toAdd = toAdd.map(veld => ({...veld, selected: v.selected}));
              this.store.dispatch(updateBenodigdeVeldpermissie({benodigdeVeldpermissies: toAdd})); 
            }
            this.store.dispatch(addSchrijfpermissie({schrijfpermissie: relatedSchrijfPermissie}));
          }
          else{
            this.store.dispatch(deleteSchrijfpermissie({schrijfpermissie: relatedSchrijfPermissie}));
          }
        }
      }
    }
    else{
      const relatedLeesPermissie = allLeesPermissies?.find(p => this.fieldsAreEqual(p, v));
      if (relatedLeesPermissie) {
        let toAdd = [{...relatedLeesPermissie, additionalProperties: v.properties}];
        toAdd = toAdd.map(veld => ({...veld, verplicht: v.verplicht, selected: v.selected}));

        const relatedEntityIndexes = [this.LEERLING_ACCOUNT, this.MEDEWERKER_ACCOUNT, this.OUDER_VERZORGER_ACCOUNT, this.LEERLING_REFERENTIE];
        if (relatedEntityIndexes.includes(relatedLeesPermissie.entityIndex)) {
          if (relatedLeesPermissie.fieldIndex !== this.UUID && relatedLeesPermissie.fieldIndex !== this.ROLLEN  && (v.selected || v.verplicht)) {
            const relatedUUIDLeesPermissie = allLeesPermissies?.find(p => p.entityIndex === relatedLeesPermissie.entityIndex  && p.fieldIndex === this.UUID);
            toAdd = [...toAdd, {...relatedUUIDLeesPermissie, verplicht: true, selected: false}];
          } else if (relatedLeesPermissie.fieldIndex === this.UUID && (!v.verplicht)) {
            toAdd = [...toAdd, ...benodigdeVeldpermissies.filter(bv => this.fieldsHaveSameCategory(bv, relatedLeesPermissie)).map(bv => ({...bv, verplicht: false, selected: false}))];
            toAdd = toAdd.filter(x=> x.fieldIndex !== this.ROLLEN)
          }
        }

        e.categorieen[0].velden.forEach(veld => {
          const veldToAdd = toAdd.find(a => this.fieldsAreEqual(a, veld));
          if (veldToAdd) {
            veld.verplicht = veldToAdd.verplicht;
            veld.selected = veldToAdd.selected;
          }
        });
        this.store.dispatch(updateBenodigdeVeldpermissie({benodigdeVeldpermissies: toAdd}));
      }
    }
  }

  private updateSchrijfpermissie(
    allLeesPermissies: AVGExtendedVeldpermissie[],
    allSchrijfPermissies: AVGWritePermissie[],
    v: Veld,
    e: Endpoint
  ): void {
      const relatedSchrijfPermissie = allSchrijfPermissies.find(p => p.index === v.fieldIndex);
      if (relatedSchrijfPermissie) {
        if (v.verplicht) {
          this.store.dispatch(addSchrijfpermissie({schrijfpermissie: relatedSchrijfPermissie}));
          // Externe toetsen (index = 3) heeft geen bijbehorende leespermissie, LVS bijlages (index = 8) heeft een leespermissie onder een ander endpoint
          if (relatedSchrijfPermissie.index !== 3 && relatedSchrijfPermissie.index !== 8) {
            let toRequire = this.getRequiredLeesPermissies(allLeesPermissies, relatedSchrijfPermissie)
              .map(veld => ({...veld, additionalProperties: e.categorieen[0].velden.find(veld2 => this.fieldsAreEqual(veld, veld2)).properties}));
            e.categorieen[0].velden.forEach(veld => {
              if (toRequire.find(r => this.fieldsAreEqual(veld, r))) {
                veld.selected = false;
                veld.verplicht = true;
              }
            });
            this.store.dispatch(updateBenodigdeVeldpermissie({benodigdeVeldpermissies: toRequire}));
          }
          //LVS bijlages (index = 8) verplicht leespermissie voor Soort bijlage
          else if(relatedSchrijfPermissie.index === 8){
            let toUpdate = allLeesPermissies
              .filter(lp => lp.entityName === 'Soort bijlage' && lp.name === 'Soort bijlage')
              .map(lp => ({...lp, verplicht: true, selected: false}))
            this.store.dispatch(updateBenodigdeVeldpermissie({benodigdeVeldpermissies: toUpdate}));
          }
        } else {
          this.store.dispatch(deleteSchrijfpermissie({schrijfpermissie: relatedSchrijfPermissie}));
        }
    }
  }

  private getRequiredLeesPermissies(allLeesPermissies: AVGExtendedVeldpermissie[], schrijfpermissie: AVGWritePermissie): AVGExtendedVeldpermissie[] {
    return allLeesPermissies
      .filter(lp => lp.entityName === schrijfpermissie.endpoint && this.leesPermissiesToBeRequiredWithWritePermission.includes(lp.name))
      .map(lp => ({...lp, verplicht: true, selected: false}));
  }


  private fieldsAreEqual(v1, v2): boolean {
    return v1.fieldIndex === v2.fieldIndex && v1.entityIndex === v2.entityIndex;
  }

  private fieldsHaveSameCategory(v1, v2): boolean {
    return v1.fieldIndex !== v2.fieldIndex && v1.entityIndex === v2.entityIndex;
  }

  public updateKoppelpartijOmschrijving(htmlText: string): void {
    this.store.dispatch(updateKoppelpartijOmschrijving({ koppelpartijOmschrijving: htmlText}));
  }

  private _mapImages(koppelpartijAfbeeldingen: {image: string, thumbImage: string}[]): string[]
    {
      return koppelpartijAfbeeldingen.map((afbeelding: any) => afbeelding.image);
    }

  public save(): void {
    if (this.readyToSend) {
      this.store.dispatch(saveKoppelpartij());
    } else {
      this.toastr.error('Er ontbreken nog gegevens. Is de koppelpartijnaam ingevuld?');
    }
  }

  // https://github.com/sanjayV/ng-image-slider/issues/80
  public fixFirstImageByDetectingChanges(): void {
    setTimeout(() => { this.cdr.detectChanges(); } , 1500);
  }

  updateAfbeeldingen(afbeeldingen: {image: string, thumbImage: string}[]): void {
    this.store.dispatch(updateKoppelpartijAfbeeldingen({ koppelpartijAfbeeldingen: this._mapImages(afbeeldingen)}));
  }

  updateOrganisaties(bijgewerkteOrganisaties: AVGOrganisatie[]): void {
    this.store.dispatch(updateKoppelpartijOrganisaties({ zichtbaarVoorOrganisaties: bijgewerkteOrganisaties}));
  }

  setDefaultPermissies(): void {
    this.store.dispatch(setEndpointsSelected({ endpointNames:
        [
          'Leerling', 'Medewerker', 'Lesgroep', 'Ouder/Verzorger', 'Vestiging', 'Plaatsing', 'Vakken', 'Leerling referentie'
        ] }));
  }

  toonDefaultPermissieKnop(): boolean {
    return 'Productie' !== environment.configurationName && 'Test' !== environment.configurationName;
  }
}

export interface AVGWritePermissieRow {
    naam: string;
    index: number;
    selected: boolean;
}
