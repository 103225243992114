import {createSelector} from '@ngrx/store';
import {selectPrivacydashboard} from '../app.state';
import {selectQueryParam} from '../router/router.selectors';
import {semScopeDescriptions} from 'src/app/dto/sem-scope-descriptions';
import {AVGWritePermissie} from '../../dto/avg-dashboard-model-classes';
import {EndpointNaamEnum, mapEndpointUitzonderingen, PermissionNaamEnum} from '../../shared/PermissiesUtil';
import {Endpoint} from "./privacydashboard.state";

// Geeft alle koppelpartijen
export const selectKoppelpartijen = createSelector(
  selectPrivacydashboard,
  state => {
    return state.koppelpartijen;
  }
);

export const selectKoppelpartij = createSelector(
  selectKoppelpartijen,
  (koppelpartijen, props) => {
      return koppelpartijen.find(item => item.uuid === props.uuid);
  }
);

// Geeft alle koppelpartijen waar een koppeling mee is
export const selectKoppelingen = createSelector(
  selectKoppelpartijen,
  (koppelpartijen) => koppelpartijen.filter(item => item.koppelingen.length > 0)
);

export const selectGeselecteerdeKoppelpartijAlgemeen = createSelector(
  selectPrivacydashboard,
  state => {
    return state.geselecteerdeKoppelpartij;
  }
);

export const selectGeselecteerdeKoppelpartij = createSelector(
  selectGeselecteerdeKoppelpartijAlgemeen,
  state => {
    return state?.koppelpartij;
  }
);

export const selectGeselecteerdeKoppelingen = createSelector(
  selectGeselecteerdeKoppelpartijAlgemeen,
  state => {
    return state?.koppelingen;
  }
);

export const selectVestigingen = createSelector(
  selectPrivacydashboard,
  state => {
    return state?.vestigingen;
  }
);

export const selectGecategoriseerdeVeldPermissies = createSelector(
  selectGeselecteerdeKoppelpartij,
  selectGeselecteerdeKoppelingen,
  (geselecteerdeKoppelpartij, geselecteerdeKoppelingen, props) => {
    let vp = [];
    let optional = [];
    let defaultToAllFields = true;
    if (!props.getEmptyFields) {
      const uuids = geselecteerdeKoppelingen?.map(k => k.vestiging.UUID);
      if (uuids?.length > 0) {
        defaultToAllFields = false;
        for (const uuid of uuids) {
          if (uuid === uuids[0]) {
            if (geselecteerdeKoppelingen?.length > 0) {
              vp = geselecteerdeKoppelingen.find(item => item.vestiging.UUID === uuid).veldpermissies.filter(i => i.verplicht && i.selected);
            }
          }
          if (geselecteerdeKoppelingen?.length > 0) {
            optional = optional
              .concat(geselecteerdeKoppelingen
                .find(item => item.vestiging.UUID === uuid).veldpermissies
                .filter(i => (!i.verplicht && i.selected))
              );
          }
        }
        for (const item of optional) {
          if (!vp.find(i => i.name === item.name && i.entityName === item.entityName && i.entityIndex === item.entityIndex)) {
            vp.push(item);
          }
        }
      }
    }

    if (vp.length === 0) {
      if (geselecteerdeKoppelpartij && defaultToAllFields){
        vp = geselecteerdeKoppelpartij.benodigdeVeldpermissies;
      }
      else if(geselecteerdeKoppelpartij){
        vp = geselecteerdeKoppelpartij.benodigdeVeldpermissies.filter(permissie => permissie.verplicht);
      }
      else return [];
    }

    const endpoints: Endpoint[] = [
      {naam: EndpointNaamEnum.LEERLING, writePermissies: false, categorieen: [
        {naam: 'Personalia', velden: []},
        {naam: 'Plaatsing', velden: []},
        {naam: 'Onderwijsinhoudelijk', velden: []},
        {naam: 'Resultaten', velden: []},
        {naam: 'Toegangspassen', velden: []},
        {naam: 'Kluisafnames', velden: []},
        {naam: 'Rooster', velden: []},
        {naam: 'Vrije velden', velden: []},
      ]},
      {naam: EndpointNaamEnum.MEDEWERKER, writePermissies: false, categorieen: [
        {naam: 'Personalia', velden: []},
        {naam: 'Aanstelling', velden: []},
        {naam: 'Toegangspassen', velden: []},
        {naam: 'Agenda', velden: []},
        {naam: "Vrije velden", velden: []},
      ]},
      {naam: EndpointNaamEnum.LESGROEP, writePermissies: false, categorieen: [
        {naam: 'Algemeen', velden: []},
      ]},
      {naam: EndpointNaamEnum.OUDER_VERZORGER, writePermissies: false,  categorieen: [
        {naam: 'Personalia', velden: []},
        {naam: 'Leerlingen', velden: []},
        {naam: "Vrije velden", velden: []},
      ]},
      {naam: EndpointNaamEnum.VESTIGING, writePermissies: false, categorieen: [
          {naam: 'Algemeen', velden: [
              {
                naam: 'Naam',
                entityIndex: 2,
                fieldIndex: null,
                selected: false,
                verplicht: true,
                properties: null,
                subFields: null,
              },
              {
                naam: 'Afkorting',
                entityIndex: 2,
                fieldIndex: null,
                selected: false,
                verplicht: true,
                properties: null,
                subFields: null,
              },
              {
                naam: 'BRIN',
                entityIndex: 2,
                fieldIndex: null,
                selected: false,
                verplicht: true,
                properties: null,
                subFields: null,
              }
            ]},
            {naam: 'Aanmeldportaal', velden: []},
      ]},
      {naam: EndpointNaamEnum.OVERIG, writePermissies: false, categorieen: [
        {naam: 'Algemeen', velden: []},
      ]},
    ];

    const medewerkerEndpoint = endpoints.find( e => e.naam === EndpointNaamEnum.MEDEWERKER);
    const leerlingEndpoint = endpoints.find( e => e.naam === EndpointNaamEnum.LEERLING);
    const ouderEndpoint = endpoints.find( e => e.naam === EndpointNaamEnum.OUDER_VERZORGER);
    const vestingEndpoint = endpoints.find( e => e.naam === EndpointNaamEnum.VESTIGING);
    const overigEndpoint = endpoints.find( e => e.naam === EndpointNaamEnum.OVERIG);

    vp = [...vp].filter(a => !a.additionalProperties.find(p => p.type === 'NestedEntity'));
    if (geselecteerdeKoppelpartij) {
      vp = [...vp].sort((a, b) => {
        const elA = geselecteerdeKoppelpartij.benodigdeVeldpermissies.find(x => x.entityIndex === a.entityIndex && x.fieldIndex === a.fieldIndex);
        const elB = geselecteerdeKoppelpartij.benodigdeVeldpermissies.find(x => x.entityIndex === b.entityIndex && x.fieldIndex === b.fieldIndex);
        return (
          geselecteerdeKoppelpartij.benodigdeVeldpermissies.indexOf(elA) - geselecteerdeKoppelpartij.benodigdeVeldpermissies.indexOf(elB)
        );
      });
    }
    vp = [...vp].sort((a, b) => {
      if (a.entityIndex > b.entityIndex) { return 1; }
      else if (a.entityIndex < b.entityIndex) { return -1; }
      else { return 0; }
    });

    for (const permission of vp) {
      const permissionMapped = {
        naam: permission.name,
        entityIndex: permission.entityIndex,
        fieldIndex: permission.fieldIndex,
        selected: permission.selected,
        verplicht: permission.verplicht,
        properties: permission.additionalProperties,
        subFields: null,
      };
      const endpoint = endpoints.find(e => permission.entityName === e.naam);
      if (endpoint) {
        if (
          [EndpointNaamEnum.LEERLING.valueOf(), EndpointNaamEnum.MEDEWERKER.valueOf()].includes(endpoint.naam) &&
          [PermissionNaamEnum.VESTIGING, PermissionNaamEnum.HOOFDVESTIGING, PermissionNaamEnum.LESGROEPEN, PermissionNaamEnum.VAKKEUZES, PermissionNaamEnum.IS_DOCENT, PermissionNaamEnum.FUNCTIE].includes(permission.name)
        ) {
          endpoint.categorieen[1].velden.push(permissionMapped);
        } else {
          endpoint.categorieen[0].velden.push(permissionMapped);
        }
      }
      if (permission.entityName === PermissionNaamEnum.LEERLING_REFERENTIE) {
        ouderEndpoint.categorieen[1].velden.push(permissionMapped);
      }
      mapEndpointUitzonderingen(permission, permissionMapped, leerlingEndpoint, medewerkerEndpoint, ouderEndpoint, overigEndpoint);

      if (permission.entityName.includes('account')) {
        const accountPermission = {
          naam: 'Account',
          entityIndex: null,
          fieldIndex: null,
          selected: permission.selected,
          verplicht: permission.verplicht,
          properties: geselecteerdeKoppelpartij?.writePermissies?.some(p => p.endpoint === permission.entityName) ? [{description: null, type: 'write'}] : null,
          subFields: [permissionMapped],
        };
        if (permission.entityName === 'Leerling account') {
          const veld = leerlingEndpoint.categorieen[0].velden.find(v => v.naam === 'Account');
          if (!veld) {
            if (accountPermission.properties) {
              leerlingEndpoint.writePermissies = true;
            }
            leerlingEndpoint.categorieen[0].velden.push(accountPermission);
          } else {
            veld.subFields.push(permissionMapped);
          }
        }
        if (permission.entityName === 'Medewerker account') {
          const veld = medewerkerEndpoint.categorieen[0].velden.find(v => v.naam === 'Account');
          if (!veld) {
            if (accountPermission.properties) {
              medewerkerEndpoint.writePermissies = true;
            }
            medewerkerEndpoint.categorieen[0].velden.push(accountPermission);
          } else {
            if (permissionMapped.fieldIndex === 7) {
              medewerkerEndpoint.categorieen[1].velden.push(permissionMapped);
            }
          }
        }
        if (permission.entityName === 'Ouder/Verzorger account') {
          const veld = ouderEndpoint.categorieen[0].velden.find(v => v.naam === 'Account');
          if (!veld) {
            if (accountPermission.properties) {
              ouderEndpoint.writePermissies = true;
            }
            ouderEndpoint.categorieen[0].velden.push(accountPermission);
          } else {
            veld.subFields.push(permissionMapped);
          }
        }
      }
    }

    const writePermissionResultaten: AVGWritePermissie[] = geselecteerdeKoppelpartij?.writePermissies?.filter(p => p.endpoint === 'Resultaten');
    if (writePermissionResultaten?.length > 0) {
      leerlingEndpoint.writePermissies = true;
      for (const wp of writePermissionResultaten) {
        const resultatenPermission = {
          naam: wp.naam,
          entityIndex: null,
          fieldIndex: null,
          selected: false,
          verplicht: true,
          properties: [{description: null, type: 'write'}],
          subFields: null,
        };
        leerlingEndpoint.categorieen[3].velden.push(resultatenPermission);
      }
    }

    const writePermissionBijlages: AVGWritePermissie[] = geselecteerdeKoppelpartij?.writePermissies?.filter(p => p.naam === 'LVS bijlagen');
    if (writePermissionBijlages?.length > 0) {
      leerlingEndpoint.writePermissies = true;
      for (const wp of writePermissionBijlages) {
        const bijlagePermission = {
          naam: wp.naam,
          entityIndex: null,
          fieldIndex: null,
          selected: false,
          verplicht: true,
          properties: [{description: null, type: 'write'}],
          subFields: null,
        };
        leerlingEndpoint.categorieen[2].velden.push(bijlagePermission);
      }
    }

    const writePermissionAanmeldportaal: AVGWritePermissie[] = geselecteerdeKoppelpartij?.writePermissies?.filter(p => p.endpoint === 'Aanmeldportaal');
    if (writePermissionAanmeldportaal?.length > 0) {
      vestingEndpoint.writePermissies = true;
      for (const wp of writePermissionAanmeldportaal) {
        const aanmeldPermission = {
          naam: wp.naam,
          entityIndex: null,
          fieldIndex: null,
          selected: false,
          verplicht: true,
          properties: [{description: null, type: 'write'}],
          subFields: null,
        };
        vestingEndpoint.categorieen[1].velden.push(aanmeldPermission);
      }
    }

    return endpoints.map(e => {
      e.categorieen = e.categorieen.filter(c => c.velden.length > 0);
      return e;
    }).filter(e => e.categorieen.length > 0);
  }
);

export const selectKoppelingenBeschikbaarForKoppelpartij = createSelector(
  selectVestigingen,
  selectGeselecteerdeKoppelingen,
  (vestigingen, koppelingen) => {
    return koppelingen?.length < vestigingen?.length;
  }
);

export const selectBeschikbareKoppelingen = createSelector(
  selectVestigingen,
  selectKoppelpartijen,
  (vestigingen, koppelingen) =>
    // Select alle koppelingen waarvoor nog vestigingen over zijn om mee te koppelen.
    koppelingen.map(
      item => ({
        koppelpartijNaam: item.koppelpartijNaam,
        koppelingen: item.koppelingen.map(koppeling => ({vestigingUUID: koppeling.vestiging.UUID})),
        uuid: item.uuid
      })
    ).filter(
      koppeling => !vestigingen.every(vestiging => (koppeling.koppelingen.map(kpl => kpl.vestigingUUID).includes(vestiging.UUID)))
    )
);

export const selectOudeWebservices = createSelector(
  selectPrivacydashboard,
  state => {
    if (state.webservices) {
      return [...state.webservices].sort((a, b) => {
        const riskA = ['WIS', 'UM'].includes(a.service) ? 0 : 1;
        const riskB = ['WIS', 'UM'].includes(b.service) ? 0 : 1;
        return (riskA !== riskB) ? (riskA > riskB ? 1 : -1) : (a.service > b.service ? 1 : -1);
    }); }
    return state.webservices;
  }
);

export const selectGeselecteerdeWebservice = createSelector(
  selectPrivacydashboard,
  state => {
    return state.geselecteerdeWebservice;
  }
);

export const selectActiveMenuItem = createSelector(
  selectQueryParam('menu'),
  menuItem => {
    return menuItem;
  }
);
export const selectGoBackURL = createSelector(
  selectPrivacydashboard,
  state => {
    return state.goBackURL;
  }
);

export const selectSemParties = createSelector(
  selectPrivacydashboard,
  state => {
    return state.semParties;
  }
);

export const selectSemPartyDetails = createSelector(
  selectPrivacydashboard,
  state => {
    return state.semPartyDetails;
  }
);

export const selectSemPartyAccessedFields = createSelector (
  selectSemPartyDetails,
  semPartyDetails => {
    return semPartyDetails?.scopes?.map(scope => semScopeDescriptions.find(description => description.mappedScope === scope));
  }
);
